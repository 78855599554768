import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/TrialsView.vue')
  },
  {
    path: '/trials/:state?',
    name: 'trials',
    component: () => import('../views/TrialsView.vue')
  },
  {
    path: '/trial/:trial',
    name: 'eventDetails',
    component: () => import('../views/TrialDetailsview.vue')
  },
  {
    path: '/individuals/:trial?',
    name: 'individuals',
    component: () => import('../views/IndividualsView.vue')
  },
  {
    path: '/individual/:individual',
    name: 'individual',
    component: () => import('../views/SingleIndividualView.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
