import { createStore } from 'vuex'

const trialsFilter = {
    state: () => ({
        selectedAccusations: [],
        selectedTrialTypes: [],
        selectedStates: [],
        selectedJudicialTypes: [],
        rtl: localStorage.getItem('lang') == 'ar'
    })
}

const individualsFilter = {
    state: () => ({
        selectedAccusations: [],
        selectedLegalSituations: [],
        selectedNationalities: [],
        selectedGenders: [],
        selectedTrialStates: []
    })
}

export const store = createStore({
    modules: {
        trialsFilter: trialsFilter,
        individualsFilter: individualsFilter
    }
}) 